import {CCallout, CRow, CCol, CBadge} from "@coreui/react";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fas);


export const StatusComponent = props => (
  <CCallout color={props.status ? props.status.class : 'success'} style={{backgroundColor: 'white', padding: '10px'}}>
    <CRow>
      <CCol md={6}>
        <h5>{props.title}</h5>
        <p><small>{props.description}</small></p>
      </CCol>
      <CCol md={6} className={"text-end"}>
        <p><CBadge color={props.status ? props.status.class : 'success'}>{props.status ? props.status.name : 'OK'}</CBadge></p>
        <FontAwesomeIcon icon={props.icon} size="2x" fixedWidth={true}/>
      </CCol>
    </CRow>
  </CCallout>
)
