import update from 'immutability-helper';
import {
  STORE_MESSAGES,
  ENUM_STATUSES,
  UPDATE_LOADING_STATUS,
  CALCULATE_COMPONENT_STATUSES
} from "../actions/data";

const initialState = {
  openMessages: {
    status: ENUM_STATUSES.IDLE,
    lastUpdate: null,
    data: {
      currentPage: null,
      total: null,
      perPage: null,
      path: null,
      lastPage: null,
      items: []
    }
  },
  components: {
    status: ENUM_STATUSES.IDLE,
    lastUpdate: null,
    statuses: {},
    data: []
  }
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOADING_STATUS:
      return update(state, {
        [action.namespace]: {
          status: { $set: action.status }
        }
      });
    case STORE_MESSAGES:
      return update(state, {
        [action.namespace]: {
          lastUpdate: { $set: new Date()},
          data: { $set: action.data }
        }
      });
    case CALCULATE_COMPONENT_STATUSES:
      let cstate = {};
      state.openMessages.data.items.forEach((msg) => {
        if(msg.status.public === true) {
          msg.components.forEach((comp) => {
            if (cstate[comp.uuid] === undefined || msg.type.weight > cstate[comp.uuid].weight) {
              cstate[comp.uuid] = msg.type;
            }
          });
        }
      });
      return update(state, {
        components: {
          statuses: { $set: cstate}
        }
      });
    default:
      return state;
  }
}
