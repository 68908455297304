import {Component} from "react";
import moment from "moment";
import {CTooltip} from "@coreui/react";

export default class UpdatingTime extends Component {

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ time: Date.now() }), 10000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <CTooltip content={moment(this.props.time).format()}>
        <span style={{textDecoration: 'underline', textDecorationStyle: 'dotted'}}>
          {moment(this.props.time).fromNow()}
        </span>
      </CTooltip>
    )
  }
}