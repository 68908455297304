import {ENUM_STATUSES} from "../actions/data";
import {CAlert, CBadge, CSpinner} from "@coreui/react";
import {Message} from "./Message";


export const MessagesOfStatus = props => (
  <div>
    <h1>{props.title} Notices {props.status === ENUM_STATUSES.SUCCESS ?
      <CBadge color="secondary">{props.items.filter(msg => msg.status.type === props.type).length}</CBadge> : ''}
    </h1>
    {props.status === ENUM_STATUSES.LOADING &&
    <CSpinner color="dark"/>
    }
    {props.status === ENUM_STATUSES.ERROR &&
    <CAlert color={'danger'}>
      Could not load data
    </CAlert>
    }
    {props.status === ENUM_STATUSES.SUCCESS &&
    <div>
      {props.items.filter(msg => msg.status.type === props.type).map((it) => {
        return (<Message {...it}/>)
      })}
      {props.items.filter(msg => msg.status.type === props.type).length === 0 &&
      <p>No notices of this type to display.</p>
      }
    </div>
    }
  </div>
)