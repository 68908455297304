import axios from "axios";
export const GET_OPEN_MESSAGES = 'GET_OPEN_MESSAGES';
export const CALCULATE_COMPONENT_STATUSES = 'CALCULATE_COMPONENT_STATUSES';
export const STORE_MESSAGES = 'STORE_MESSAGES';
export const STORE_ERROR = 'STORE_ERROR';
export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';

export const ENUM_STATUSES = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success'
};

// actions

function calculateComponentStatuses() {
  return {
    type: CALCULATE_COMPONENT_STATUSES
  }
}

function updateStatus(namespace, status) {
  return {
    type: UPDATE_LOADING_STATUS,
    namespace,
    status
  }
}

function storeMessages(namespace, data) {
  return {
    type: STORE_MESSAGES,
    namespace,
    data
  };
}

export function dataFetchError(error) {
  return {
    type: STORE_ERROR,
    error
  }
}

// thunks

export function getOpenMessages() {

  return function(dispatch) {
    dispatch(updateStatus('openMessages', ENUM_STATUSES.LOADING));
    return axios.get(process.env.REACT_APP_API_URL+'/data').then((data) => {
      dispatch(updateStatus('openMessages', ENUM_STATUSES.SUCCESS));
      dispatch(storeMessages('openMessages', data.data));
      dispatch(calculateComponentStatuses());
    }).catch((error) => {
        dispatch(updateStatus('openMessages', ENUM_STATUSES.ERROR));
        dispatch(dataFetchError(error.message));
      },
    );
  };
}

export function getComponents() {
  return function(dispatch) {
    dispatch(updateStatus('components', ENUM_STATUSES.LOADING));
    return axios.get(process.env.REACT_APP_API_URL+'/data/components').then((data) => {
      dispatch(updateStatus('components', ENUM_STATUSES.SUCCESS));
      dispatch(storeMessages('components', data.data));
    }).catch((error) => {
        dispatch(updateStatus('components', ENUM_STATUSES.ERROR));
        dispatch(dataFetchError(error.message));
      },
    );
  };
}