import {CRow, CCol, CCard, CBadge, CCardHeader, CCardBody} from "@coreui/react";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import moment from "moment";
import UpdatingTime from "./UpdatingTime";

library.add(fas);

export const Message = props => (
  <CCard style={{marginBottom: '6px'}} className={`mb-3 border-top-${props.type.class} border-top-3`}>
    <CCardHeader color={props.type.class} textColor="white">
      <CRow>
        <CCol><h5 style={{margin: 0}}>{props.title}</h5></CCol>
        <CCol className={"text-end"}>
          <FontAwesomeIcon icon={props.type.icon} fixedWidth style={{marginRight: '8px'}}/>
          <small>{props.type.name} {moment(props.startDate).diff(moment()) > 0 ? 'starting' : 'started'} <UpdatingTime time={props.startDate} /></small>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md={4}>
          <CCard>
            <CCardBody>
              <div>
                <h6>Status</h6>
                <p><CBadge color={props.status.class}>{props.status.name}</CBadge></p>
              </div>
              <div>
                <h6>Start Date</h6>
                <p>{moment(props.startDate).format()}</p>
              </div>
              <div>
                <h6>End Date</h6>
                {props.endDate ?
                  <p>{moment(props.endDate).format()}</p> : <p>Pending</p>
                }
              </div>
              <div>
                <h6>Affects</h6>
                <p>{props.components.map((c) => {
                  return(<CBadge style={{marginRight: '4px'}} color={"secondary"}>{c.title}</CBadge>)
                })}</p>
              </div>
              <div>
                <h6>Locations</h6>
                <p>{props.locations.map((c) => {
                  return(<CBadge style={{marginRight: '4px'}} color={"secondary"}>{c.name}</CBadge>)
                })}</p>
              </div>
              <div>
                <h6>Posted By</h6>
                <p>{props.adminuser.firstName} {props.adminuser.lastName}<br/>
                  <small><UpdatingTime time={props.times.created}/></small></p>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <ReactMarkdown>
          {props.content}
          </ReactMarkdown>
          {props.updates.map((upd) => {
            return(<CCard>
              <CCardHeader color={"secondary"}>Update posted by {upd.adminuser.firstName} {upd.adminuser.lastName}{' '}
                <UpdatingTime time={upd.times.created}/></CCardHeader>
              <CCardBody>
                <ReactMarkdown>{upd.update_content}</ReactMarkdown>
              </CCardBody>
            </CCard>)
          })}
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
)
